import type { NextPage } from 'next'
import Head from 'next/head'
import { useSession } from 'next-auth/react'
import Logo from './logo'
import Link from 'next/link'

const MenuItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="mr-1 px-2 py-1 hover:cursor-pointer hover:bg-slate-600 hover:bg-opacity-10 hover:underline">
      {children}
    </div>
  )
}

const Layout: NextPage<{ children: React.ReactNode }> = ({ children }) => {
  const { data: session, status } = useSession()
  return (
    <div className="inset-0 flex min-h-screen flex-col items-center bg-[url(/grid.svg)] bg-center py-2">
      <div className=""></div>
      <Head>
        <title>sprouts.design</title>
        <link rel="icon" href="/logo-square.png" />
      </Head>
      <div className="absolute right-4 flex w-full flex-row bg-transparent text-sm text-slate-500">
        <div className="ml-6 flex-1">
          <Link href="/" className="flex items-center">
            <Logo /> <div className="ml-[-4px] text-base font-bold text-slate-700">sprouts.design</div>
          </Link>
        </div>

        <div className="flex flex-row items-center">
          {status === 'loading' ? (
            ''
          ) : (
            <>
              {session ? (
                <Link href={'/account'}>
                  <MenuItem>Manage your account</MenuItem>
                </Link>
              ) : (
                <Link href="/login">
                  <MenuItem>Log in</MenuItem>
                </Link>
              )}
            </>
          )}
          <Link href="/faq">
            <MenuItem>FAQ</MenuItem>
          </Link>
          <Link href="/pricing">
            <MenuItem>Pricing</MenuItem>
          </Link>
          <Link href="/feedback">
            <MenuItem>Feedback</MenuItem>
          </Link>
        </div>
      </div>
      <main className="relative mb-8 mt-20 w-[460px] rounded-lg bg-amber-100 p-5 text-left text-slate-700 shadow-xl ring-1 ring-gray-900/5 ">
        {children}
      </main>
    </div>
  )
}

export default Layout
