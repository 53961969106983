import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import Layout from '../lib/components/layout'
import type { Session } from 'next-auth'

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps<{ session: Session }>) {
  return (
    <SessionProvider session={session}>
      <Layout>
        <Component session={session} {...pageProps} />
      </Layout>
    </SessionProvider>
  )
}

export default MyApp
